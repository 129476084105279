import { useUserWithoutStore } from './store/modules/user'
import { useGlobalWithoutStore } from './store/modules/global'
import { setupStore } from './store'
import { setupComponent } from './components/setup-component'
import { createApp } from 'vue'
import './styles/app.css'
import 'windi.css'
import './assets/icon-font/iconfont.css' // 解决H5样式引入问题
import './assets/nut-icon-font/iconfont.css' // 解决H5样式引入问题
import { setupFundebug } from './common/debug'
import setupUpdateCheck from './common/update-check'
import { fixArray } from './common/fixArray'


/* data 数据 */
const globalStore = useGlobalWithoutStore()
const userStore = useUserWithoutStore()


const app = createApp({

  onLaunch() {
    // console.log(process.env.NODE_ENV)
    fixArray() // 修复数组at()方法
    globalStore.setReference()

    setupUpdateCheck()
    // globalStore.setSystemInfo()
    // globalStore.setMiniProgramInfo() // 设置小程序信息
    if (userStore.token) {
      userStore.setUserInfo()

      /*       console.log(useEnv, 'useEnv') */
    }

    /*     setTimeout(() => {
      console.log(useEnv, 'useEnv')
    }, 1000) */
  },
})


setupComponent(app)
setupStore(app)
setupFundebug(app)
export default app
