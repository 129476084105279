import Taro from '@tarojs/taro'

/* 本地缓存对象 */
export const useStorage = {

  /**
   * @description: 设置本地缓存
   * @param {string} key 缓存的键名
   * @param {any} val 缓存值
   * @return {*} *
   */

  setItem(key: string, val: any) {
    return Taro.setStorage({
      key,
      data: JSON.stringify(val)
    })
  },
  setItemAndValue(key: string, val: any) {
    return Taro.setStorage({
      key,
      data: JSON.stringify(val)
    })
  },

  /**
   * @description: 获取缓存值
   * @param {string} key 缓存的键名
   * @return {*} *
   */
  getItem(key: string) {
    const v = Taro.getStorageSync(key)
    return v ? JSON.parse(v) : ''
  },

  /**
   * @description: 移除缓存项
   * @param {string} key 缓存键名
   */
  removeItem(key: string) {
    Taro.removeStorageSync(key)
  },
}
